<template>
    <div>
        <v-toolbar
            dense
            color="primary darken-2"
            dark
            extended
            extension-height="200"
            flat
        >
            <v-toolbar-title class="mx-auto" slot="extension">
                <span v-if="$store.state.isAuthenticated">Welcome <b>{{$store.state.user_info["user"]["username"]}} 😃</b>, to PettyCash</span>
                <span v-else >Welcome 😃 to <b>PettyCash</b>,</span>
                <v-layout row v-if="!$store.state.isAuthenticated">
                    <v-flex wrap xs12 >
                        <v-btn outline color="white" class="outine-2" :to="{name: 'login'}">
                            <span> LOGIN</span>
                        </v-btn>
                    </v-flex>
                    <v-flex wrap xs12 >
                        <v-btn outline color="white"  class="outine-2" :to="{name: 'signup'}">
                            <span> CREATE ACCOUNT</span>
                        </v-btn>
                    </v-flex>
                </v-layout>
                <v-layout row v-else>
                    <v-flex wrap xs12 >
                        <h2 class="saving_heading">We discipline you into financial success.</h2>
                    </v-flex>
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>
        <br>
        <v-container>
            <v-layout row>
                <v-flex xs12 md10 offset-md1 lg10 offset-lg1>
                    <v-card flat class="card--flex-toolbar" color="transparent">
                        <v-container fluid grid-list-lg>
                            <v-layout row wrap>
                                <v-flex xs12 class="mt-1">
                                    <h3 class="white--text">Discover Our Petty Ca$h Services</h3>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout row wrap>
                                <v-flex xs12 sm6 md6 lg4 v-for="item in products_services" :key="item.id" class="pa-2">
                                    <v-hover>
                                        <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 4}`" style="border-radius: 5px; font-size: small" :to="item.link">
                                            <v-layout row >
                                                <v-flex xs8 class="text-xs-left">
                                                    <h5 class="subheading mt-4 pl-3 pt-4 pb-0 mb-0"><b>{{item.title}}</b></h5>
                                                </v-flex>
                                                <v-flex xs5  class="text-xs-left">
                                                    <v-btn  fab :color="item.color" class="mt-2 ml-3 mb-0 mr-2 white--text">
                                                        <i :class="item.icon"></i>
                                                    </v-btn>
                                                </v-flex>
                                            </v-layout>

                                            <v-card-title primary-title class="pb-0 mt-0 pt-1" style="color: #424242">
                                                <div>
                                                    <div class="caption mt-0 mb-0 pb-2">{{item.description}}</div>
                                                </div>
                                            </v-card-title>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-container>
            <v-layout row>
                <v-flex xs12 md10 offset-md1>
                    <v-slide-y-transition mode="out-in">
                        <div>
                            <h1>Our Team</h1>
                            <p>Our team has expertise in engineering, accounting, marketing, and law to deliver innovative fintech solutions. Our platform is secure and reliable for safe financial transactions. Trust us for exceptional service and cutting-edge technology to meet your financial needs.</p>
                            <v-layout row wrap class="">
                                <v-flex xs12 sm6 md6 lg3  v-for="item in team_members" :key="item.id" class="pa-1">
                                    <v-hover>
                                        <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 2}`" :to="item.link" style="cursor: pointer; border-radius: 5px">
                                            <v-img
                                                    :src="item.get_thumbnail"
                                                    aspect-ratio="2.75"
                                                    height="200px"
                                            > </v-img>

                                            <v-card-title primary-title >
                                                <div>
                                                    <b class="subheading mb-0" >{{item.position}}</b>
                                                    <h4 class="title ma-0 text--grey" ><b>{{item.name}}</b></h4>
                                                </div>
                                            </v-card-title>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
        <v-container class="elevation-12 white darken-2" >
            <v-layout row>
                <v-flex xs12 md10 offset-md1>
                    <v-slide-y-transition mode="out-in">
                        <div style="z-index: 9999!important">
                            <h1>News</h1>
                            <p> Get the latest news, tips and strategies on how to best save and manage your petty cash.</p>
                            <div class=" text-xs-right ma-0 pa-0">
                                <v-text-field 
                                    name="email"
                                    label="Enter Your Email"
                                    id="email" outline 
                                ></v-text-field>
                                <v-btn class="white--text" round color="teal" >
                                    <span> Subscribe For More</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>

    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                products_services: [
                    {
                        id: '1',
                        title: 'Savings',
                        color: 'orange darken-2',
                        icon: 'fa-solid fa-piggy-bank fa-2x',
                        description: 'Saving and accumulating small amounts of money via our mobile platform (turning your piggy banks into a virtual mobile App)',
                        link: '/savings',
                        add_link: false
                    },
                    {
                        id: '2',
                        title: 'Market Place',
                        color: 'teal darken-2',
                        icon: 'fa-solid fa-shopping-cart fa-2x',
                        description: 'A buy now pay later model which helps you to purchase goods and services on credit, thanks to your financial discipline record from your objective saving campaigns',
                        link: '/market_place',
                        add_link: false
                    },
                    {
                        id: '3',
                        title: 'Njangi',
                        color: 'blue darken-2',
                        icon: 'fa-solid fa-hands-helping fa-2x',
                        description: 'Start njangi groups with your friends and family members (Raising funds via Person to Person community)',
                        link: '/njangi',
                        add_link: false
                    },
                    {
                        id: '4',
                        title: 'Fundraising',
                        color: 'purple darken-2',
                        icon: 'fa-solid fa-hand-holding-usd fa-2x',
                        description: 'Invest, donate and follow-up in community projects strengthening communities around the world',
                        link: '/funding',
                        add_link: false
                    },
                    {
                        id: '5',
                        title: 'Monitor',
                        color: 'green darken-2',
                        icon: 'fa-solid fa-chart-line fa-2x',
                        description: 'A pettycash service that helps you track your expenses and income and proposes petty savings and investments',
                        link: '/monitor',
                        add_link: false
                    }
                ],
                team_members: [],
            }
        },

        mounted(){
            this.getOurTeamInfo();
            document.title = "PettyCash | Home "
        },
        
        methods:{
            async getOurTeamInfo(){
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/home/get/our_team/info/')
                    .then(response => {
                        this.team_members = response.data
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            }
        }
    }
</script>

<style scoped>
    @media (max-width: 340px) {
        .saving_heading{
            font-size: 12px
        }
    }
    @media (max-width: 499px) {
        .saving_heading{
            font-size: 14px
        }
    }

    .outine-2 {
        border: 2px solid white;
    }

    .card--flex-toolbar {
        margin-top: -124px;
    }

    .learn-more-btn {
        text-transform: initial;
        text-decoration: underline;
    }
</style>
